<script>
  import { variables } from '$lib/variables.js'
</script>

<footer>
  <div class="inner">
    <div class="main">
      <h3><img src="/logo/wordmark-dark.svg" alt="Turbomaps" /></h3>
      <p>&copy; {new Date().getUTCFullYear()} Turbomaps Limited</p>
    </div>
    <nav class="secondary">
      <div>
        <h4>Resources</h4>
        <ul>
          <li><a href="/docs">Docs</a></li>
          <li><a href="/terms">Terms</a></li>
          <li><a href="/privacy">Privacy</a></li>
          <li />
        </ul>
      </div>
      <div>
        <h4>More</h4>
        <ul>
          <li><a href={variables.supportLink}>Contact</a></li>
          <li><a href="https://status.turbomaps.io">Status</a></li>
          <li><a href={variables.feedbackLink}>Suggest a feature</a></li>
        </ul>
      </div>
      <!-- <div>
        <h4>Follow us</h4>
        <div class="social">
          <a href="https://twitter.com/turbomapsapp"
            ><img src="/icons/site/twitter.svg" alt="twitter" /></a
          >
        </div>
      </div> -->
    </nav>
  </div>
</footer>

<style lang="scss">
  footer {
    background: var(--nav-background);
    padding: 2rem 1.5rem;
    color: #fff;
  }
  .inner {
    margin: 0 auto;
    width: 100%;
    max-width: 1100px;

    @media (min-width: 800px) {
      display: flex;
      align-items: center;
    }
  }

  .main {
    flex: 1;
  }
  .secondary {
    display: flex;
    width: 100%;
    max-width: 525px;
    gap: 1.5rem;
    flex-direction: column;
    div {
      flex: 1;
    }

    @media (min-width: 500px) {
      flex-direction: row;
      gap: 2.5rem;
    }
  }
  h3 {
    margin: 0;
  }
  h3 img {
    height: 24px;
    vertical-align: top;
  }
  h4 {
    margin: 0 0 0.25rem;
    font-size: 14px;
    font-weight: 600;
  }
  p {
    margin: 0.75rem 0 1.5rem;
    font-size: 12px;
    color: #aaa;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  li {
    font-size: 14px;
    a {
      line-height: 26px;
      color: #ccc;
      text-decoration: none;
      &:hover,
      &:focus {
        text-decoration: underline;
        color: #fff;
      }
    }
  }
  // .social {
  //   margin: 0.25rem 0 0 -4px;
  //   a {
  //     opacity: 0.75;

  //     &:hover,
  //     &:focus {
  //       opacity: 1;
  //     }
  //   }
  // }
</style>
