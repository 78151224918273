<script>
  import Nav from './Nav.svelte'
  import Footer from '$lib/site/Footer.svelte'
  export let nav = false,
    footer = false,
    padding = false
</script>

{#if nav}
  <Nav />
{/if}

<div class="inner" class:padding class:footer>
  <slot />
</div>

{#if footer}
  <Footer />
{/if}

<style lang="scss">
  .inner {
    margin: 0 auto;
    padding: 0 0.5rem;
    box-sizing: border-box;
    width: 100%;
    max-width: 1100px;
    color: #000;
  }
  /* this enables the footer to be sticky to the bottom of the page */
  .footer {
    flex: 1;
  }
  .padding {
    padding: 2rem 1rem 3rem;

    @media (min-width: 500px) {
      padding: 2rem 2rem 3rem;
    }

    @media (min-width: 1116px) {
      padding: 2.5rem 0.5rem 3rem;
    }
  }
</style>
